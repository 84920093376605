<template>
  <LiefengContent>
    <template v-slot:title>服务记录</template>
    <template v-slot:toolsbarRight>
      <Button @click="register" type="primary">登记服务</Button>
      <Button type="warning" @click="drawerStatus = true"
        >查看服务对象信息</Button
      >
      <Button
        type="error"
        @click="$router.push('/archivesdevservice')"
        icon="ios-arrow-back"
        >返回</Button
      >
      <Drawer width="320" title="服务对象信息" v-model="drawerStatus">
        <div>档案编号：{{ serviceObj.archiveNum }}</div>
        <div>服务对象：{{ serviceObj.name }}</div>
        <div>所属社区：{{ serviceObj.orgName }}</div>
        <div>手机号：{{ serviceObj.mobile }}</div>
        <div>服务状态：{{ serviceObj.serviceStatus }}</div>
        <div>分类：{{ serviceObj.difficultType }}</div>
        <div>分级：{{ serviceObj.difficultLevel }}</div>
      </Drawer>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="page"
        @hadlePageSize="hadlePageSize"
        :pagesizeOpts="[20, 30, 50, 100]"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
      ></LiefengTable>

      <!-- 登记服务/修改 -->
      <LiefengModal
        :title="addChangeTitle"
        :value="addChangeStatus"
        height="500px"
        @input="addChangeStatusFn"
      >
        <template v-slot:contentarea>
          <Form
            ref="addChangeForm"
            :model="addChangeData"
            :label-colon="true"
            :label-width="90"
            :rules="addChangeValidator"
          >
            <FormItem label="服务对象">
              <p>{{ serviceObj.name }}</p>
            </FormItem>
            <FormItem label="服务时间" prop="serviceTime">
              <DatePicker
                type="datetime"
                v-model="addChangeData.serviceTime"
                placeholder="请选择服务时间"
              ></DatePicker>
            </FormItem>
            <FormItem label="跟进人" prop="followName">
              <Input
                :maxlength="20"
                v-model.trim="addChangeData.followName"
              ></Input>
            </FormItem>
            <FormItem label="服务项" prop="projectIds">
              <Select v-model="addChangeData.projectIds" multiple>
                <Option
                  :value="item.projectId"
                  v-for="item in serviceOptionList"
                  :key="item.projectId"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="具体服务描述" :label-width="100">
              <Input
                :maxlength="400"
                type="textarea"
                show-word-limit
                :rows="4"
                placeholder="请输入具体服务描述，不超过400字"
                v-model.trim="addChangeData.content"
              ></Input>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button
            type="info"
            @click="
              cancelModelTip = true;
              addChangeStatusFn(false);
            "
            style="margin-right: 10px"
            >取消</Button
          >
          <Button type="primary" @click="saveAddChange">保存</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/archivesrecord')
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import LiefengContent from "@/components/LiefengContent";
import { format } from "@/utils/formatTime";
export default {
  data() {
    return {
      talbeColumns: [
        {
          title: "服务项",
          key: "projects",
          minWidth: 200,
          align: "center",
        },
        {
          title: "服务时间",
          key: "serviceTime",
          width: 180,
          align: "center",
        },
        {
          title: "具体服务描述",
          key: "content",
          minWidth: 150,
          align: "center",
        },
        {
          title: "跟进社工",
          key: "followName",
          width: 130,
          align: "center",
        },
        {
          title: "创建时间",
          key: "gmtCreate",
          width: 180,
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: 200,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.infoId = params.row.recordId;
                      this.changeRecord();
                    },
                  },
                },
                "修改内容"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.infoId = params.row.recordId;
                      this.delRow();
                    },
                  },
                },
                "删除记录"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      total: 0,
      page: 1,
      loading: true,
      pageSize: 20,

      //查看抽屉
      drawerStatus: false,
      serviceObj: {},

      //登记服务/修改
      cancelModelTip: true, //true代表关闭时弹窗提示
      addChangeStatus: false,
      addChangeIden: true, //新增修改标识，新增代表true
      addChangeTitle: "",
      infoId: "",
      addChangeData: {},
      serviceOptionList: [], //服务项配置列表
      addChangeValidator: {
        serviceTime: [
          {
            required: true,
            type: "date",
            message: "请选择服务时间",
            trigger: "change",
          },
        ],
        followName: [
          { required: true, message: "请输入跟进人", trigger: "blur" },
        ],
        projectIds: [
          {
            required: true,
            type: "array",
            message: "请选择服务项",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    //登记服务
    register() {
      this.$refs.addChangeForm.resetFields();
      this.addChangeTitle = "登记服务";
      this.addChangeIden = true;
      this.addChangeData = {
        serviceTime: new Date(),
        followName:parent.vue.loginInfo.userinfo.realName,
      };
      this.addChangeStatus = true;
    },
    //修改服务
    changeRecord() {
      this.$refs.addChangeForm.resetFields();
      this.addChangeTitle = "修改服务";
      this.$Message.loading("正在加载数据...");
      this.addChangeData = {};
      this.addChangeIden = false;
      this.$get("/archives/api/pc/serviceRecord/queryByRecordId", {
        recordId: this.infoId,
      })
        .then((res) => {
          this.$Message.destroy();
          this.addChangeStatus = true;
          if (res.code == 200 && res.data) {
            this.addChangeData = res.data;
            this.addChangeData.serviceTime = new Date(res.data.serviceTime);
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.error({
            background: true,
            content: "获取数据失败",
          });
        });
    },
    saveAddChange() {
      this.$refs.addChangeForm.validate((status) => {
        if (status) {
          this.cancelModelTip = false;
          let url = "";
          let params = {};
          if (this.addChangeIden) {
            //新增参数
            url = "/archives/api/pc/serviceRecord/add";
            params = {
              archiveId: this.$route.query.id,
            };
          } else {
            //修改
             url = "/archives/api/pc/serviceRecord/update";
            params = {
              recordId: this.infoId,
            };
          }
          this.$post(url, {
            ...this.addChangeData,
            ...params,
              oemCode: parent.vue.oemInfo.oemCode,
              orgCode: parent.vue.loginInfo.userinfo.orgCode,
            projectIds: this.addChangeData.projectIds.join(","),
            serviceTime: format(
              this.addChangeData.serviceTime,
              "yyyy-MM-dd HH:mm:ss"
            ),
            staffName: parent.vue.loginInfo.userinfo.realName,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "保存成功",
                });
                this.addChangeStatus = false;
                this.hadlePageSize({
                  page: this.page,
                  pageSize: this.pageSize,
                });
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            })
            .catch((err) => {
              this.$Message.error({
                background: true,
                content: "保存失败",
              });
            });
        }
      });
    },
    addChangeStatusFn(status) {
      if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.addChangeStatus = status;
          },
        });
      } else {
        this.addChangeStatus = status;
      }
    },
    //删除记录
    delRow() {
      this.$Modal.confirm({
        title: "温馨提示",
        content: "<p>您正在删除信息，是否继续？</p>",
        onOk: () => {
          this.$post("/archives/api/pc/serviceRecord/delete", {
            recordId: this.infoId,
              oemCode: parent.vue.oemInfo.oemCode,
              orgCode: parent.vue.loginInfo.userinfo.orgCode,
          }).then((res) => {
            if (res.code == 200) {
              this.$Message.success({
                background: true,
                content: "删除成功",
              });
              this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
              });
            } else {
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
          });
        },
      });
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$post("/archives/api/pc/serviceRecord/queryRecordByPage", {
        page: obj.page,
        pageSize: obj.pageSize,
        archiveId: this.$route.query.id,
      }).then((res) => {
        if (res.code == 200 && res.data) {
          this.serviceObj = {
            archiveNum: res.data.archiveNum,
            name: res.data.name,
            orgName: res.data.orgName,
            mobile: res.data.mobile,
          };
          switch (res.data.difficultType) {
            case "1":
              this.serviceObj.difficultType = "低保低收入对象";
              break;
            case "2":
              this.serviceObj.difficultType = "特困人员";
              break;
            case "3":
              this.serviceObj.difficultType = "留守老年人";
              break;
            case "4":
              this.serviceObj.difficultType = "独居孤寡老人";
              break;
            case "5":
              this.serviceObj.difficultType = "困境儿童";
              break;
            case "6":
              this.serviceObj.difficultType = "贫困重度残疾人";
              break;
            default:
              this.serviceObj.difficultType = "其他";
              break;
          }
          switch (res.data.difficultLevel) {
            case "1":
              this.serviceObj.difficultLevel = "一级";
              break;
            case "2":
              this.serviceObj.difficultLevel = "二级";
              break;
            case "3":
              this.serviceObj.difficultLevel = "三级";
              break;
            case "4":
              this.serviceObj.difficultLevel = "四级";
              break;
          }
          switch (res.data.serviceStatus) {
            case "1":
              this.serviceObj.serviceStatus = "未开始服务";
              break;
            case "2":
              this.serviceObj.serviceStatus = "服务中";
              break;
            case "3":
              this.serviceObj.serviceStatus = "服务执行中";
              break;
            case "4":
              this.serviceObj.serviceStatus = "停止服务";
              break;
          }
          if (
            res.data.serviceRecordPages.code == 200 &&
            res.data.serviceRecordPages.dataList
          ) {
            res.data.serviceRecordPages.dataList.map((item) => {
              if (item.serviceTime) {
                item.serviceTime = format(
                  item.serviceTime,
                  "yyyy-MM-dd HH:mm:ss"
                );
              }
              if (item.gmtCreate) {
                item.gmtCreate = format(
                  new Date(item.gmtCreate),
                  "yyyy-MM-dd HH:mm:ss"
                );
              }
            });
            this.tableData = res.data.serviceRecordPages.dataList;
            this.total = res.data.serviceRecordPages.maxCount;
            this.page = res.data.serviceRecordPages.currentPage;
            this.pageSize = res.data.serviceRecordPages.pageSize;
          }
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
        this.loading = false;
      });
    },
  },
  async created() {
    await this.$get("/archives/api/pc/serviceProject/queryList", {
      oemCode: parent.vue.oemInfo.oemCode,
      orgCode: parent.vue.loginInfo.userinfo.orgCode,
    }).then((res) => {
      if (res.code == 200 && res.dataList) {
        this.serviceOptionList = res.dataList;
      } else {
        this.$Message.error({
          background: true,
          content: "无法获取服务项配置，请联系管理员处理",
        });
      }
    });
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
.ivu-drawer-body {
  div {
    margin-bottom: 14px;
  }
}
/deep/.ivu-modal-close {
  display: none;
}
</style>